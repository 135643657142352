// import React, { Component } from 'react';
import React from 'react';
// import AppBar from '@material-ui/core/AppBar';
// import Toolbar from '@material-ui/core/Toolbar';
// import TypoGraphy from '@material-ui/core/Typography';
import Dashboard from './Dashboard';
// import Report from './Report';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  // Link,
  // useRouteMatch,
  // useParams
} from "react-router-dom";


import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import theme from './theme';
import Home from './Home';
// import Token from './Token';
// import TokensByCreatorTable from './TokensByCreatorTable';
// import AllTokens from './AllTokens';
// import CreatorsPage from './CreatorsPage';
// import PricesPage from './PricesPage';
// import ChartsPage from './ChartsPage';
// import NotificationsPage from './NotificationsPage';
// import CreatorDetailsPage from './CreatorDetailsPage';
// import TokenDetailsPage from './TokenDetailsPage';
// import NftListPage from './NftListPage';
// import NftDetailsPage from './NftDetailsPage';
// import OwnerDetailsPage from './OwnerDetailsPage';
// import TokenBalancesPage from './TokenBalancesPage';

// class App extends Component {
//   render() {
//     return (
//       <Dashboard />
//     );
//   }
// }

// export default App;


export default function App() {
  const [reportComplete, setReportComplete] = React.useState(false);
  return (
    <Router>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Dashboard reportComplete={reportComplete} setReportComplete={setReportComplete} >
          <Switch>
            {/* <Route path="/about">
              <About />
            </Route> */}
            {/* <Route path="/reports">
              <Reports reportComplete={reportComplete} setReportComplete={setReportComplete} />
            </Route> */}
            {/* <Route path="/tokens">
              <AllTokens />
            </Route>
            <Route path="/nfts">
              <NftListPage />
            </Route>
            <Route path="/creators">
              <CreatorsPage />
            </Route>
            <Route path="/prices">
              <PricesPage />
            </Route>
            <Route path="/charts">
              <ChartsPage />
            </Route>
            <Route path="/balances">
              <TokenBalancesPage />
            </Route>
            <Route path="/notifications">
              <NotificationsPage />
            </Route>
            <Route path="/token">
              <Tokens />
            </Route>
            <Route path="/nft">
              <Nfts />
            </Route>
            <Route path="/creator">
              <TokensByCreator />
            </Route>
            <Route path="/owner">
              <Owner />
            </Route> */}
            <Route path="/">
              <Home />
            </Route>
          </Switch>
        </Dashboard>
      </ThemeProvider>
    </Router>
  );
}

// function Home() {
//   return <h2>Home</h2>;
// }

// function About() {
//   return <h2>About</h2>;
// }

// function Reports(props) {
//   let match = useRouteMatch();

//   return (
//       <Switch>
//         <Route path={`${match.path}/:reportId`}>
//           <Report reportComplete={props.reportComplete} setReportComplete={props.setReportComplete} />
//         </Route>
//         <Route path={match.path}>
//           <h3>Please select a report.</h3>
//         </Route>
//       </Switch>
//   );
// }

// function Tokens(props) {
//   let match = useRouteMatch();

//   return (
//       <Switch>
//         <Route path={`${match.path}/:tokenId`}>
//           {/* <Token /> */}
//           <TokenDetailsPage />
//         </Route>
//         {/* <Route path={match.path}>
//           <h3>Please select a token.</h3>
//         </Route> */}
//       </Switch>
//   );
// }

// function TokensByCreator(props) {
//   let match = useRouteMatch();

//   return (
//       <Switch>
//         <Route path={`${match.path}/:creatorId`}>
//           {/* <TokensByCreatorTable /> */}
//           <CreatorDetailsPage />
//         </Route>
//         {/* <Route path={match.path}>
//           <h3>Please select a token.</h3>
//         </Route> */}
//       </Switch>
//   );
// }

// function Nfts(props) {
//   let match = useRouteMatch();

//   return (
//       <Switch>
//         <Route path={`${match.path}/:nftId`}>
//           <NftDetailsPage />
//         </Route>
//       </Switch>
//   );
// }

// function Owner(props) {
//   let match = useRouteMatch();

//   return (
//       <Switch>
//         <Route path={`${match.path}/:ownerId`}>
//           <OwnerDetailsPage />
//         </Route>
//       </Switch>
//   );
// }

// function Report() {
//   let { reportId } = useParams();
//   return <h3>Requested report ID: {reportId}</h3>;
// }
