import React, { useEffect, useState } from "react";
import Container from '@material-ui/core/Container';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

// import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
// import Card from '@material-ui/core/Card';
// import CardContent from '@material-ui/core/CardContent';
import { green } from '@material-ui/core/colors';
import { red } from '@material-ui/core/colors';
import { grey } from '@material-ui/core/colors';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
// import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
// import Toolbar from '@material-ui/core/Toolbar';
import Paper from '@material-ui/core/Paper';
// import Checkbox from '@material-ui/core/Checkbox';
// import IconButton from '@material-ui/core/IconButton';
// import Tooltip from '@material-ui/core/Tooltip';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Switch from '@material-ui/core/Switch';
// import DeleteIcon from '@material-ui/icons/Delete';
// import FilterListIcon from '@material-ui/icons/FilterList';
// import { Link } from "react-router-dom";
import Chip from '@material-ui/core/Chip';
import FaceIcon from '@material-ui/icons/Face';
// import DoneIcon from '@material-ui/icons/Done';
// import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
// import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import TrendingFlatIcon from '@material-ui/icons/TrendingFlat';
import {PercentChip} from './components/Chips';
import {CryptoIcon} from './components/Chips';
import {CryptoIconTest} from './components/Chips';


const styles = (theme) => ({
  root: {
    width: '100%',
    height: '100%',
    flexGrow: 1,
  },

  green: {
    color: '#fff',
    backgroundColor: green[500],
  },
  red: {
    color: '#fff',
    backgroundColor: red[500],
  },

  card1: {
    backgroundColor: red[100],
    color: '#000000',
  },

  // card2: {
  //   backgroundColor: red[100],
  //   color: '#000000',
  // },

  card2: {
    backgroundColor: '#CCCCCC',
    color: '#000000',
  },

  card3: {
    backgroundColor: '#E0E4F4',
    color: '#000000',
  },

  card4: {
    backgroundColor: '#F3EAF4',
    color: '#000000',
  },

  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },

  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },

});


function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: 'crypto', numeric: false, disablePadding: false, label: 'Crypto' },
  // { id: 'size', numeric: false, disablePadding: false, label: 'Size' },
  { id: 'buy_price', numeric: false, disablePadding: false, label: 'Buy price' },
  { id: 'sell_price', numeric: false, disablePadding: false, label: 'Sell price' },
  { id: 'percent', numeric: false, disablePadding: false, label: 'Percent change' },

  { id: 'status', numeric: false, disablePadding: false, label: 'Status' },
  { id: 'buy_datetime', numeric: false, disablePadding: false, label: 'Buy date' },
  // { id: 'sell_price', numeric: false, disablePadding: false, label: 'Sell price' },
  { id: 'sell_datetime', numeric: false, disablePadding: false, label: 'Sell date' },

  { id: 'buy_algorithm', numeric: false, disablePadding: false, label: 'Buy strategy' },
  { id: 'sell_algorithm', numeric: false, disablePadding: false, label: 'Sell strategy' },

  // { id: 'upper', numeric: true, disablePadding: false, label: 'Upper prediction' },
  // { id: 'lower', numeric: true, disablePadding: false, label: 'Lower prediction' },
  // { id: 'percent', numeric: false, disablePadding: false, label: '% Change' },
  // { id: 'created_at', numeric: false, disablePadding: false, label: 'Created date' },
  // { id: 'creator', numeric: false, disablePadding: false, label: 'Creator' },
  // { id: 'transaction_id', numeric: false, disablePadding: false, label: 'Contract' },
];


// function PercentChip(props) {
//   const { value } = props;
//   let label = value + "%";
//   // let color = "#c0c0c0";
//   let color = "#c0c0c0";
//   let icon = <TrendingFlatIcon />;
//   if (parseFloat(value) > 0) {
//     // green
//     color = green[100];
//     icon = <ArrowUpwardIcon />;
//   } else if (parseFloat(value) < 0) {
//     // red
//     color = red[100];
//     icon = <ArrowDownwardIcon />;
//   } else {
//     // gray
//     color = grey[100];
//     icon = <TrendingFlatIcon />;
//   }
//   return (
//     <Chip size="small" icon={icon} label={label} style={{backgroundColor: color}} />
//   )
// }

function TradeStatusChip(props) {
  const { status } = props;
  let label = status;
  let color = "#c0c0c0";
  let icon = <TrendingFlatIcon />;
  if (status === "done") {
    // green
    color = green[100];
  } else if (status === "open" || status === "pending") {
    // red
    color = red[100];
  } else {
    // gray
    color = grey[100];
  }
  return (
    <Chip size="small" icon={<FaceIcon />} label={label} color={color} />
  )
}

function EnhancedTableHead(props) {
  const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};


function StockMarketTableLosersTest(props) {
    const { classes } = props;

    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('symbol');
    const [tradesList, setTradesList] = useState([]);

    const handleRequestSort = (event, property) => {
      const isAsc = orderBy === property && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(property);
    };

    useEffect(() => {
        // console.log("useEffect starting...");
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' }
        };
        // let url = process.env.REACT_APP_API_URL + '/wordcloud/losers';
        // let url = 'http://0.0.0.0:8080/token/tokens';
        let url = process.env.REACT_APP_API_URL + "/trading/trades/done";

        // let url = '/static/sp500-losers.json';
        // console.log("url: ", url);
        fetch(url, requestOptions)
            .then(response => response.json())
            .then(data => {
                // console.log("got initial data: ", data);
                setTradesList(data.trades);
            });
        // fetch('./data/output.json')
        // .then(response => response.json())
        // .then(data => {
        //     console.log("got initial data: ", data);
        //     setTokensList(data);
        // });
    }, []);

    return (
      <Container className={classes.root} component="section" justify="center" alignItems="center" justifyContent="center">
      <div className={classes.root}>
        {/* <Typography variant="h2" component="h2" align="center">
          Completed Trades
        </Typography> */}
        <Typography style={{fontSize: 50}} marked="center" align="center" component="h2">
          Completed Trades
        </Typography>
        {/* <Typography>
          List of recent trades.
        </Typography> */}
      <TableContainer component={Paper}>
        {/* <Table className={classes.table} size="small" aria-label="tokens table"> */}
        <Table className={classes.table} aria-label="tokens table">
          <EnhancedTableHead
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
          />
          <TableBody>
          {stableSort(tradesList, getComparator(order, orderBy))
                .map((trade, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;
                  let percent_current = ((trade.current_price - trade.buy_price) / trade.buy_price) * 100;
                  let percent_sell = ((trade.sell_price - trade.buy_price) / trade.buy_price) * 100;
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={trade.trade_id}
                    >
                      <TableCell component="th" id={labelId} scope="row">
                        {/* <Link to={`/token/${token.transaction_id}`} style={{ color: 'inherit', textDecoration: 'inherit'}}>
                          {token.symbol}
                        </Link> */}
                        {/* <Link to={`/token/${token.transaction_id}`} style={{ color: 'inherit' }}>
                          {token.symbol}
                        </Link> */}
                        <CryptoIcon value={trade.crypto} />
                        {/* <br /> */}
                        {/* <CryptoIconTest value={trade.crypto} /> {trade.crypto.toUpperCase()} */}
                      </TableCell>
                      {/* <TableCell>{trade.size}</TableCell> */}
                      <TableCell>{trade.buy_price * 1}</TableCell>
                      <TableCell>
                        {trade.sell_price &&
                          <div>{trade.sell_price * 1}</div>
                        }
                        {!trade.sell_price &&
                          <div>N/A - Trade still open.<br /><br />Current price: {trade.current_price}</div>
                        }
                      </TableCell>
                      {/* <TableCell>{trade.current_price}</TableCell> */}
                      <TableCell>
                        {trade.sell_price &&
                          // <div>{percent_sell.toLocaleString(undefined, {maximumFractionDigits:1})}%</div>
                          <div>
                            <PercentChip value={percent_sell.toLocaleString(undefined, {maximumFractionDigits:1})} />
                          </div>
                        }
                        {!trade.sell_price &&
                          <div>N/A - Trade still open.<br /><br />Percent change from buy price: {percent_current.toLocaleString(undefined, {maximumFractionDigits:1})}%</div>
                        }
                      </TableCell>

                      <TableCell>{trade.status}</TableCell>

                      <TableCell>{trade.buy_datetime}</TableCell>

                      <TableCell>
                        {trade.sell_datetime &&
                          <div>{trade.sell_datetime}</div>
                        }
                        {!trade.sell_datetime &&
                          <div>N/A - Trade still open.</div>
                        }
                      </TableCell>

                      <TableCell>
                        {trade.buy_algorithm === "random_buy" &&
                          <div>Algorithm 1</div>
                        }
                        {trade.buy_algorithm === "algorithm_r1" &&
                          <div>Algorithm R1</div>
                        }
                        {trade.buy_algorithm === "algorithm_r2" &&
                          <div>Algorithm R2</div>
                        }
                        {trade.buy_algorithm === "algorithm_r3" &&
                          <div>Algorithm R3</div>
                        }
                        {trade.buy_algorithm === "big_dipper" &&
                          <div>Algorithm 2</div>
                        }
                        {trade.buy_algorithm === "little_dipper" &&
                          <div>Algorithm 3</div>
                        }
                        {trade.buy_algorithm === "prophet" &&
                          <div>Algorithm 4</div>
                        }
                      </TableCell>
                      <TableCell>
                        {trade.sell_algorithm === "random_profit" &&
                          <div>Algorithm 1</div>
                        }
                        {trade.sell_algorithm === "ten_percent_gain" &&
                          <div>Algorithm 2</div>
                        }
                        {trade.sell_algorithm === "prophet" &&
                          <div>Algorithm 3</div>
                        }
                        {trade.sell_algorithm === "low_profit" &&
                          <div>Algorithm 4</div>
                        }
                      </TableCell>

                      {/* <TableCell>
                        {(signal.trendline_slope && signal.trendline_slope < 0) &&
                          <div>Down</div>
                        }
                        {(signal.trendline_slope && signal.trendline_slope > 0) &&
                          <div>Up</div>
                        }
                        {(signal.trendline_slope && signal.trendline_slope == 0) &&
                          <div>Sideways</div>
                        }
                      </TableCell> */}
                      {/* <TableCell>{signal.current_price}</TableCell>
                      <TableCell>{signal.middle}</TableCell>
                      <TableCell>{signal.future_date}</TableCell> */}
                      {/* <TableCell>{signal.upper}</TableCell> */}
                      {/* <TableCell>{signal.lower}</TableCell> */}
                      {/* <TableCell>{signal.change_percent.toLocaleString(undefined, {maximumFractionDigits:2})}%</TableCell>
                      <TableCell>{signal.created_at}</TableCell>
                      <TableCell> */}
                        {/* <Link to={`/token/${token.transaction_id}`} style={{ color: 'inherit' }}>
                          {token.transaction_id}
                        </Link> */}
                      {/* </TableCell> */}
                    </TableRow>
                  );
                })}
          </TableBody>
        </Table>
      </TableContainer>
      </div>
      </Container>
    );
}

export default withStyles(styles)(StockMarketTableLosersTest);
