/* background images from: https://unsplash.com/t/textures-patterns */
/* image url for formatting: https://images.unsplash.com/photo-1617661915994-56ae790f3dd1?auto=format&fit=crop&w=600&q=150 */
import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ButtonBase from '@material-ui/core/ButtonBase';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';

const styles = (theme) => ({
  root: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(4),
  },
  images: {
    // marginTop: theme.spacing(8),
    display: 'flex',
    flexWrap: 'wrap',
  },
  imageWrapper: {
    position: 'relative',
    display: 'block',
    padding: 0,
    borderRadius: 0,
    height: '40vh',
    [theme.breakpoints.down('sm')]: {
      width: '100% !important',
      height: 250,
    },
    '&:hover': {
      zIndex: 1,
    },
    '&:hover $imageBackdrop': {
      opacity: 0.15,
    },
    '&:hover $imageMarked': {
      opacity: 0,
    },
    '&:hover $imageTitle': {
      border: '4px solid currentColor',
    },
  },
  imageButton: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.common.white,
  },
  imageSrc: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: 'cover',
    backgroundPosition: 'center 40%',
  },
  imageBackdrop: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    background: theme.palette.common.black,
    opacity: 0.5,
    transition: theme.transitions.create('opacity'),
  },
  imageTitle: {
    position: 'relative',
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px 14px`,
  },
  imageMarked: {
    height: 3,
    width: 18,
    background: theme.palette.common.white,
    position: 'absolute',
    bottom: -2,
    left: 'calc(50% - 9px)',
    transition: theme.transitions.create('opacity'),
  },
});

function ProductCategories(props) {
  const { classes } = props;
  const [tradeStats, setTradeStats] = useState({});

  useEffect(() => {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' }
    };
    let url = process.env.REACT_APP_API_URL + "/trading/stats";
    fetch(url, requestOptions)
        .then(response => response.json())
        .then(data => {
            setTradeStats(data.stats);
        });
  }, []);


  return (
    <Container className={classes.root} component="section">
      {/* <Typography variant="h2" marked="center" align="center" component="h2">
        Trading Statistics
      </Typography> */}
      <Typography style={{fontSize: 50}} marked="center" align="center" component="h2">
        Trading Statistics
      </Typography>
      <div className={classes.images}>

          <ButtonBase
            key={`Gains`}
            className={classes.imageWrapper}
            style={{
              width: '50%',
            }}
          >
            <div
              className={classes.imageSrc}
              style={{
                backgroundImage: `url(${`/images/stats-bg1-600.jpeg`})`,
              }}
            />
            <div className={classes.imageBackdrop} />
            <div className={classes.imageButton}>
              <Typography
                component="h3"
                variant="h4"
                color="inherit"
                className={classes.imageTitle}
              >
                {`Gains`}
                <br />
                <br />
                ${tradeStats.total_profit}
                {/* <div className={classes.imageMarked} /> */}
              </Typography>
            </div>
          </ButtonBase>

          <ButtonBase
            key={`Return Rate`}
            className={classes.imageWrapper}
            style={{
              width: '50%',
            }}
          >
            <div
              className={classes.imageSrc}
              style={{
                backgroundImage: `url(${`/images/stats-bg2-600.jpeg`})`,
              }}
            />
            <div className={classes.imageBackdrop} />
            <div className={classes.imageButton}>
              <Typography
                component="h3"
                variant="h4"
                color="inherit"
                className={classes.imageTitle}
              >
                {`Return Rate`}
                <br />
                <br />
                {tradeStats.return_rate}%
                {/* <div className={classes.imageMarked} /> */}
              </Typography>
            </div>
          </ButtonBase>

          <ButtonBase
            key={`Trades`}
            className={classes.imageWrapper}
            style={{
              width: '50%',
            }}
          >
            <div
              className={classes.imageSrc}
              style={{
                backgroundImage: `url(${`/images/stats-bg3-600.jpeg`})`,
              }}
            />
            <div className={classes.imageBackdrop} />
            <div className={classes.imageButton}>
              <Typography
                component="h3"
                variant="h4"
                color="inherit"
                className={classes.imageTitle}
              >
                {`Trades`}
                <br />
                <br />
                {tradeStats.number_of_trades}
                {/* <div className={classes.imageMarked} /> */}
              </Typography>
            </div>
          </ButtonBase>

          <ButtonBase
            key={`Algorithms`}
            className={classes.imageWrapper}
            style={{
              width: '50%',
            }}
          >
            <div
              className={classes.imageSrc}
              style={{
                backgroundImage: `url(${`/images/stats-bg4-600.jpeg`})`,
              }}
            />
            <div className={classes.imageBackdrop} />
            <div className={classes.imageButton}>
              <Typography
                component="h3"
                variant="h4"
                color="inherit"
                className={classes.imageTitle}
              >
                {`Algorithms`}
                <br />
                <br />
                {tradeStats.number_of_systems}
                {/* <div className={classes.imageMarked} /> */}
              </Typography>
            </div>
          </ButtonBase>

          <ButtonBase
            key={`Start Date`}
            className={classes.imageWrapper}
            style={{
              width: '50%',
            }}
          >
            <div
              className={classes.imageSrc}
              style={{
                backgroundImage: `url(${`/images/stats-bg5-600.jpeg`})`,
              }}
            />
            <div className={classes.imageBackdrop} />
            <div className={classes.imageButton}>
              <Typography
                component="h3"
                variant="h4"
                color="inherit"
                className={classes.imageTitle}
              >
                {`Initial Capital`}
                <br />
                <br />
                $400.00
                {/* <div className={classes.imageMarked} /> */}
              </Typography>
            </div>
          </ButtonBase>

          <ButtonBase
            key={`Start Date`}
            className={classes.imageWrapper}
            style={{
              width: '50%',
            }}
          >
            <div
              className={classes.imageSrc}
              style={{
                backgroundImage: `url(${`/images/stats-bg6-600.jpeg`})`,
              }}
            />
            <div className={classes.imageBackdrop} />
            <div className={classes.imageButton}>
              <Typography
                component="h3"
                variant="h4"
                color="inherit"
                className={classes.imageTitle}
              >
                {`Start Date`}
                <br />
                <br />
                October 5, 2021
                {/* <div className={classes.imageMarked} /> */}
              </Typography>
            </div>
          </ButtonBase>


      </div>
    </Container>
  );
}

ProductCategories.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProductCategories);

