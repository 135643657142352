import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
// import Button from '@material-ui/core/Button';
// import Link from '@material-ui/core/Link';
// import { Link as RouterLink } from 'react-router-dom';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
// import MoneyIcon from '@material-ui/icons/Money';
// import VpnKeyIcon from '@material-ui/icons/VpnKey';
// import MailOutlineIcon from '@material-ui/icons/MailOutline';
// import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
// import TimelineIcon from '@material-ui/icons/Timeline';
// import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
// import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
// import SettingsIcon from '@material-ui/icons/Settings';
import AssessmentIcon from '@material-ui/icons/Assessment';
import { withRouter } from 'react-router-dom';


const styles = (theme) => ({
  root: {
    display: 'flex',
    backgroundColor: theme.palette.secondary.light,
    overflow: 'hidden',
  },
  container: {
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(15),
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    // padding: theme.spacing(0, 5),
    padding: theme.spacing(0, 1),
  },
  title1: {
    marginBottom: theme.spacing(7),
  },
  title1Blurb: {
    fontSize: 50,
    marginBottom: theme.spacing(7),
  },
  bigIcon: {
    fontSize: 100,
  },
  title: {
    marginBottom: theme.spacing(7),
  },
  number: {
    fontSize: 24,
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.secondary.main,
    fontWeight: theme.typography.fontWeightMedium,
  },
  image: {
    height: 55,
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  curvyLines: {
    pointerEvents: 'none',
    position: 'absolute',
    top: -180,
    opacity: 0.7,
  },
  button: {
    marginTop: theme.spacing(8),
  },
  actionButton: {
    marginTop: theme.spacing(1),
  },
});

function ProductHowItWorks(props) {
  const { classes } = props;

  return (
    <section className={classes.root}>
      <Container className={classes.container}>
        <img
          src="/static/themes/onepirate/productCurvyLines.png"
          className={classes.curvyLines}
          alt="curvy lines"
        />
        {/* <Typography variant="h4" marked="center" className={classes.title1} component="h2">
          NAXOSYS
        </Typography> */}
        <Typography id="learnmore" className={classes.title1Blurb}>
          How it works
        </Typography>
        {/* <Typography variant="h4" marked="center" className={classes.title} component="h2">
          Explore
        </Typography> */}
        <div>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <div className={classes.item}>
                <AssessmentIcon className={classes.bigIcon} />
                <Typography variant="h5" align="center">
                  Our proprietary systems constantly analyze various cryptocurrencies for trading opportunities.
                </Typography>
                {/* <Button
                  color="secondary"
                  size="large"
                  variant="contained"
                  className={classes.actionButton}
                  component="a"
                  href="/tokens"
                >
                  Tokens
                </Button> */}
              </div>
            </Grid>
            <Grid item xs={12} md={4}>
              <div className={classes.item}>
                <TrendingUpIcon className={classes.bigIcon} />
                <Typography variant="h5" align="center">
                  Multiple systems, algorithms, and strategies are used to identify profitable trades.
                </Typography>
                {/* <Button
                  color="secondary"
                  size="large"
                  variant="contained"
                  className={classes.actionButton}
                  component="a"
                  href="/creators"
                >
                  Creators
                </Button> */}
              </div>
            </Grid>
            <Grid item xs={12} md={4}>
              <div className={classes.item}>
                <AttachMoneyIcon className={classes.bigIcon} />
                <Typography variant="h5" align="center">
                  After buying, we then use various strategies to maximize profits and reduce risk.
                </Typography>
                {/* <Button
                  color="secondary"
                  size="large"
                  variant="contained"
                  className={classes.actionButton}
                  component="a"
                  href="/prices"
                >
                  Prices
                </Button> */}
              </div>
            </Grid>
            {/* <Grid item xs={12} md={3}>
              <div className={classes.item}>
                <TimelineIcon className={classes.bigIcon} />
                <Typography variant="h5" align="center">
                  Explore Token Price Charts
                </Typography>
                <Button
                  color="secondary"
                  size="large"
                  variant="contained"
                  className={classes.actionButton}
                  component="a"
                  href="/charts"
                >
                  Charts
                </Button>
              </div>
            </Grid> */}
          </Grid>
          <br />
          <br />
          <br />
          <Grid container spacing={3}>
            {/* <Grid item xs={12} md={3}>
              <div className={classes.item}>
                <AccountBalanceIcon className={classes.bigIcon} />
                <Typography variant="h5" align="center">
                  Explore Wallet Holdings
                </Typography>
                <Button
                  color="secondary"
                  size="large"
                  variant="contained"
                  className={classes.actionButton}
                  component="a"
                  href="/balances"
                >
                  Holdings
                </Button>
              </div>
            </Grid> */}
            {/* <Grid item xs={12} md={3}>
              <div className={classes.item}>
                <MailOutlineIcon className={classes.bigIcon} />
                <Typography variant="h5" align="center">
                  Get notifified about new tokens
                </Typography>
                <Button
                  color="secondary"
                  size="large"
                  variant="contained"
                  className={classes.actionButton}
                  component="a"
                  href="/notifications"
                >
                  Notifications
                </Button>
              </div>
            </Grid> */}
          </Grid>
        </div>
        {/* <Button
          color="secondary"
          size="large"
          variant="contained"
          className={classes.button}
          component="a"
          href="#explore"
        >
          Get started
        </Button> */}
      </Container>
    </section>
  );
}

ProductHowItWorks.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withRouter(ProductHowItWorks));

