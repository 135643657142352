import React from "react";
import Chip from '@material-ui/core/Chip';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import TrendingFlatIcon from '@material-ui/icons/TrendingFlat';
import { green } from '@material-ui/core/colors';
import { red } from '@material-ui/core/colors';
import { grey } from '@material-ui/core/colors'
import Icon from "react-crypto-icons";


export function PercentChip(props) {
    const { value } = props;
    let label = value + "%";
    // let color = "#c0c0c0";
    let color = "#c0c0c0";
    let icon = <TrendingFlatIcon />;
    if (parseFloat(value) > 0) {
      // green
      color = green[100];
      icon = <ArrowUpwardIcon />;
    } else if (parseFloat(value) < 0) {
      // red
      color = red[100];
      icon = <ArrowDownwardIcon />;
    } else {
      // gray
      color = grey[100];
      icon = <TrendingFlatIcon />;
    }
    return (
      <Chip size="small" icon={icon} label={label} style={{backgroundColor: color}} />
    )
}

export function CryptoIcon(props) {
    const { value } = props;
    // let color = "#c0c0c0";
    let color = "#c0c0c0";
    // let icon = <TrendingFlatIcon />;
    let crypto_tmp = value.split("-")[0];
    let crypto_lower = crypto_tmp.toLowerCase();
    // icon_name = "btc";
    let icon = <Icon name={crypto_lower} size={16} />;
    let label = value;
    // switch (crypto_lower) {
    //     case "btc":
    //         icon = <ArrowUpwardIcon />;
    //     case "eth":
    //     case "sol":
    //     case "dot":
    //     case "ada":
    //     default:
    //         icon = <TrendingFlatIcon />;
    // }
    return (
        // <span>
        //     <Icon name={crypto_lower} size={16} />
        // </span>
        // <Chip size="small" icon={icon} label={label} style={{backgroundColor: color}} />
        <Chip size="small" icon={icon} label={label} style={{backgroundColor: "#ffffff"}} />
    )
}


export function CryptoIconTest(props) {
    const { value } = props;
    // let color = "#c0c0c0";
    let color = "#c0c0c0";
    // let icon = <TrendingFlatIcon />;
    let crypto_tmp = value.split("-")[0];
    let crypto_lower = crypto_tmp.toLowerCase();
    // icon_name = "btc";
    let icon = <Icon name={crypto_lower} size={16} />;
    let label = value;
    // switch (crypto_lower) {
    //     case "btc":
    //         icon = <ArrowUpwardIcon />;
    //     case "eth":
    //     case "sol":
    //     case "dot":
    //     case "ada":
    //     default:
    //         icon = <TrendingFlatIcon />;
    // }
    return (
        // <span>
            <Icon name={crypto_lower} size={16} />
        // </span>
        // <Chip size="small" icon={icon} label={label} style={{backgroundColor: color}} />
        // <Chip size="small" icon={icon} label={label} style={{backgroundColor: "#ffffff"}} />
    )
}
