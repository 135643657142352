import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
// import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import ProductHeroLayout from './ProductHeroLayout';
// import Link from '@material-ui/core/Link';
// import { Link as RouterLink } from 'react-router-dom';
// import Grid from '@material-ui/core/Grid';
import theme from './theme';


// const backgroundImage = 'https://images.unsplash.com/photo-1534854638093-bada1813ca19?auto=format&fit=crop&w=1400&q=80';
// const backgroundImage = 'https://images.unsplash.com/photo-1630476387426-52c6e0b14e50?auto=format&fit=crop&w=2333&q=80';

// current production - red circuitry
//const backgroundImage = 'https://images.unsplash.com/photo-1580584126903-c17d41830450?auto=format&fit=crop&w=2737&q=1';

// testing - blue circuitry
const backgroundImage = 'https://images.unsplash.com/photo-1550751827-4bd374c3f58b?auto=format&fit=crop&w=2737&q=1';

// really like:
// const backgroundImage = 'https://images.unsplash.com/photo-1510511459019-5dda7724fd87?auto=format&fit=crop&w=2850&q=80';


const styles = (theme) => ({
  background: {
    backgroundImage: `url(${backgroundImage})`,
    backgroundColor: '#333333', // Average color of the background image.
    backgroundPosition: 'center',
  },
  button: {
    minWidth: 200,
  },
  h5: {
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(4),
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(10),
    },
  },
  more: {
    marginTop: theme.spacing(2),
  },
});

function ProductHero(props) {
  const { classes } = props;
  const [tradeStats, setTradeStats] = useState({});

  useEffect(() => {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' }
    };
    let url = process.env.REACT_APP_API_URL + "/trading/stats";
    fetch(url, requestOptions)
        .then(response => response.json())
        .then(data => {
            setTradeStats(data.stats);
        });
  }, []);

  return (
    <ProductHeroLayout backgroundClassName={classes.background}>
      {/* Increase the network loading priority of the background image. */}
      <img style={{ display: 'none' }} src={backgroundImage} alt="increase priority" />
      {/* <Typography color="inherit" align="center" variant="h2" marked="center">
        Automated <br /><br /> crypto <br /><br /> trading <br /><br />
      </Typography> */}
        <Typography gutterBottom style={{fontSize: 80, backgroundColor: "#000000", padding: theme.spacing(1)}} color="inherit" marked="center" align="center" component="h2">
          Automated Crypto Trading
        </Typography>
        <Typography gutterBottom style={{fontSize: 50, backgroundColor: "#000000", padding: theme.spacing(1)}} color="secondary" marked="center" align="center" component="h2">
          Real trades. Real money.
        </Typography>
        {(tradeStats && tradeStats.total_profit) &&
          // <Typography style={{fontSize: 35, color: "#64dd17", backgroundColor: "#000000", padding: theme.spacing(1)}} marked="center" align="center" component="h2">
          //   Total Gain: +${tradeStats.total_profit}<br />
          //   Return Rate: +{tradeStats.return_rate}%
          // </Typography>        
          <Typography style={{fontSize: 35, color: "#64dd17", backgroundColor: "#000000", padding: theme.spacing(1)}} marked="center" align="center" component="h2">
            Total Gain: +${tradeStats.total_profit}<br />
            Return Rate: +{tradeStats.return_rate}%
          </Typography>
        }

        {/* <Typography style={{fontSize: 25, backgroundColor: "#000000", padding: theme.spacing(1)}} color="secondary" marked="center" align="center" component="h2">
          Open Trades:
          DOT -0.5%
        </Typography> */}
      {/* <Grid container spacing={3}>
        <Grid item>
          <img src={`/images/btc-logo-100x100.png`} alt="Bitcoin (BTC)" />
        </Grid>
        <Grid item>
          <img src={`/images/eth-logo-61x100.png`} alt="Ethereum (ETH)" />
        </Grid>
        <Grid item>
          <img src={`/images/sol-logo-100x100.png`} alt="Solana (SOL)" />
        </Grid>
        <Grid item>
          <img src={`/images/dot-logo-100x100.png`} alt="Polkadot (DOT)" />
        </Grid>
        <Grid item>
          <img src={`/images/ada-logo-100x100.png`} alt="Cardano (ADA)" />
        </Grid>
      </Grid> */}
      {/* <Typography color="inherit" align="center" variant="h5" className={classes.h5}>
        Get notifications if your favorite cryptocurrencies go above or below your desired price
      </Typography> */}
      {/* <Button
        color="secondary"
        variant="contained"
        size="large"
        className={classes.button}
        component="a"
        href="#learnmore"
      >
        Learn more
      </Button> */}
      {/* <Typography variant="body2" color="inherit" className={classes.more}>
        Discover the experience
      </Typography> */}
    </ProductHeroLayout>
  );
}

ProductHero.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProductHero);

