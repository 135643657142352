// import React, { useEffect, useState } from "react";
import React from "react";
// import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
// import ButtonBase from '@material-ui/core/ButtonBase';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
// import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Icon from "react-crypto-icons";

const styles = (theme) => ({
  root: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(4),
    width: '100%',
    height: '100%',
    flexGrow: 1,
    // backgroundColor: theme.palette.secondary.light,
  },
  // root: {
  //   width: '100%',
  //   height: '100%',
  //   flexGrow: 1,
  // },
  images: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexWrap: 'wrap',
  },
  imageWrapper: {
    position: 'relative',
    display: 'block',
    padding: 0,
    borderRadius: 0,
    height: '40vh',
    [theme.breakpoints.down('sm')]: {
      width: '100% !important',
      height: 250,
    },
    '&:hover': {
      zIndex: 1,
    },
    '&:hover $imageBackdrop': {
      opacity: 0.15,
    },
    '&:hover $imageMarked': {
      opacity: 0,
    },
    '&:hover $imageTitle': {
      border: '4px solid currentColor',
    },
  },
  imageButton: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.common.white,
  },
  imageSrc: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: 'cover',
    backgroundPosition: 'center 40%',
  },
  imageBackdrop: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    background: theme.palette.common.black,
    opacity: 0.5,
    transition: theme.transitions.create('opacity'),
  },
  imageTitle: {
    position: 'relative',
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px 14px`,
  },
  imageMarked: {
    height: 3,
    width: 18,
    background: theme.palette.common.white,
    position: 'absolute',
    bottom: -2,
    left: 'calc(50% - 9px)',
    transition: theme.transitions.create('opacity'),
  },
});

function CryptoInfo(props) {
  const { classes } = props;
  // const [tradeStats, setTradeStats] = useState({});

  // useEffect(() => {
  //   const requestOptions = {
  //       method: 'GET',
  //       headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' }
  //   };
  //   let url = process.env.REACT_APP_API_URL + "/trading/stats";
  //   fetch(url, requestOptions)
  //       .then(response => response.json())
  //       .then(data => {
  //           setTradeStats(data.stats);
  //       });
  // }, []);


  return (
    <section className={classes.root}>
      <Container justify="center" alignItems="center" justifyContent="center">
        <Typography style={{fontSize: 50}} marked="center" align="center" component="h2">
          Cryptocurrencies
        </Typography>
        <Grid
          container
          spacing={0}
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item style={{textAlign: "center", width: "100%", backgroundColor: "#e0e0e0"}} xs={12} md={4}>
            <Grid
              container
              spacing={3}
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
              <Grid item style={{textAlign: "center", marginTop: "16px"}} xs={6}>
                <Icon name="btc" size={100} />
              </Grid>
              <Grid item style={{textAlign: "center", marginBottom: "16px"}} xs={6}>
                <Typography>
                  Bitcoin (BTC-USD)
                </Typography>
              </Grid>

            </Grid>
          </Grid>
          <Grid item style={{textAlign: "center", width: "100%", backgroundColor: "#d0d0d0"}} xs={12} md={4}>
            <Grid
              container
              spacing={3}
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
              <Grid item style={{textAlign: "center", marginTop: "16px"}} xs={6}>
                <Icon name="eth" size={100} />
              </Grid>
              <Grid item style={{textAlign: "center", marginBottom: "16px"}} xs={6}>
                <Typography>
                  Ethereum (ETH-USD)
                </Typography>
              </Grid>

            </Grid>
          </Grid>
          <Grid item style={{textAlign: "center", width: "100%", backgroundColor: "#e0e0e0"}} xs={12} md={4}>
            <Grid
              container
              spacing={3}
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
              <Grid item style={{textAlign: "center", marginTop: "16px"}} xs={6}>
                <Icon name="sol" size={100} />
              </Grid>
              <Grid item style={{textAlign: "center", marginBottom: "16px"}} xs={6}>
                <Typography>
                  Solana (SOL-USD)
                </Typography>
              </Grid>

            </Grid>
          </Grid>
          <Grid item style={{textAlign: "center", width: "100%", backgroundColor: "#d0d0d0"}} xs={12} md={4}>
            <Grid
              container
              spacing={3}
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
              <Grid item style={{textAlign: "center", marginTop: "16px"}} xs={6}>
                <Icon name="dot" size={100} />
              </Grid>
              <Grid item style={{textAlign: "center", marginBottom: "16px"}} xs={6}>
                <Typography>
                  Polkadot (DOT-USD)
                </Typography>
              </Grid>

            </Grid>
          </Grid>
          <Grid item style={{textAlign: "center", width: "100%", backgroundColor: "#e0e0e0"}} xs={12} md={4}>
            <Grid
              container
              spacing={3}
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
              <Grid item style={{textAlign: "center", marginTop: "16px"}} xs={6}>
                <Icon name="ada" size={100} />
              </Grid>
              <Grid item style={{textAlign: "center", marginBottom: "16px"}} xs={6}>
                <Typography>
                  Cardano (ADA-USD)
                </Typography>
              </Grid>

            </Grid>
          </Grid>
        </Grid>
      </Container>
    </section>
  );
}

CryptoInfo.propTypes = {
  // classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CryptoInfo);
