import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
// import Card from '@material-ui/core/Card';
// import CardContent from '@material-ui/core/CardContent';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
// import InputForm from './InputForm';
// import Box from '@material-ui/core/Box';
import ProductHero from './ProductHero';
import ProductHowItWorks from './HowItWorks';
import TradeStats from './TradeStats';
// import TradeStatsGrid from './TradeStatsGrid';
import CryptoInfo from './CryptoInfo';
// import FavoriteTokensTable from './FavoriteTokensTable';
// import FeaturedTokensTable from './FeaturedTokensTable';
// import NewTokensTable from './NewTokensTable';
// import AllSignalsTable from './AllSignalsTable';
import OpenTradesTable from './OpenTradesTable';
import DoneTradesTable from './DoneTradesTable';
// import BsvPriceConverter from './BsvPriceConverter';
// import SatoshiPriceConverter from './SatoshiPriceConverter';
// import BsvSatoshiPriceConverter from './BsvSatoshiPriceConverter';
// import PortfolioChart from './PortfolioChart';
// import InformationTechnologies from './InformationTechnologies';


const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    flexGrow: 1,
  },

  card_home: {
    backgroundColor: '#FFFFFF',
    color: '#000000',
  },

  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },

  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },

  exploreSection: {
    // paddingTop: 'calc(100vh - 64px)',
    display: 'block',
    height: '64px',
    marginTop: '-64px',
    visibility: 'hidden',
  }

}));

export default function Home(props) {
  const classes = useStyles();

  return (
    <div>
      <ProductHero />
      <ProductHowItWorks />
      {/* <InformationTechnologies /> */}
      <TradeStats />
      {/* <TradeStatsGrid /> */}
      <CryptoInfo />
      {/* <AllTokensTable /> */}
    <Container maxWidth="lg" className={classes.container}>
      <Grid container spacing={3}>
        {/* <Grid item xs={12}>
          <Paper className={classes.paper}>
            <BsvSatoshiPriceConverter />
          </Paper>
        </Grid> */}
        {/* <Grid item xs={12}>
          <Paper className={classes.paper}>
            <BsvPriceConverter />
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <SatoshiPriceConverter />
          </Paper>
        </Grid> */}
        {/* <Grid item xs={12}>
          <Paper className={classes.paper}>
            <PortfolioChart />
          </Paper>
        </Grid> */}
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <OpenTradesTable />
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <DoneTradesTable />
          </Paper>
        </Grid>

        {/* <Grid item xs={12}>
          <Paper className={classes.paper}>
            <AllSignalsTable />
          </Paper>
        </Grid> */}
        {/* <div id="explore" className={classes.exploreSection}>
        </div> */}
        {/* Main Content */}
        {/* {props.children} */}
      </Grid>
      {/* <Box pt={4}>
        <Copyright />
      </Box> */}
    </Container>
    </div>
    // <Grid item xs={12}>
    //   <Paper className={classes.paper}>
    //     <div className={classes.root}>
    //         <Grid container direction="row" alignItems="stretch" spacing={3}>
    //             <Grid item xs={12}>
    //             <Card className={`${classes.root} ${classes.card_home}`}>
    //                 <CardContent>
    //                 How it works
    //                 </CardContent>
    //             </Card>
    //             </Grid>
    //         </Grid>
    //     </div>
    //   </Paper>
    // </Grid>
  );
}
